import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CREATE_PLEDGEE_MUTATION } from '../graphql/mutations/createPledgee';
import { useRecoilValue } from 'recoil';
import { selectedPortfolioState } from '@bakerweb/client-state';

interface CreatePledgee {
  pledgeCode: string;
  pledgeeName: string;
}

export const useCreatePledgee = () => {
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const [createPledgeeMutation, { data, loading, error }] = useMutation(CREATE_PLEDGEE_MUTATION);

  const createPledgee = async ({ pledgeCode, pledgeeName }: CreatePledgee) => {
    const variables = {
      pledgeeCreateDto: {
        portfolioId,
        pledgeCode,
        pledgeeName
      }
    };

    try {
      await createPledgeeMutation({ variables });
      toast.success('Pledgee created successfully!');
    } catch (err) {
      toast.error('There was an error creating the pledgee.');
    }
  };

  return { createPledgee, data, loading, error };
};
