export const secClassMap: { [key: number]: string } = {
  1: 'Treas',
  2: 'Agy',
  3: 'Corp',
  4: 'Tax Muni',
  5: 'Muni',
  6: 'CD',
  7: 'MBS',
  8: 'CMO',
  9: 'Corp FL',
  10: 'MBS FL',
  11: 'CMO FL',
  12: 'Agy FL',
  13: 'SBA',
  14: 'SBA FL',
  15: 'STK/PFD',
  16: 'STK/PFD FL',
  17: 'CMBS',
  18: 'CMBS FL',
  19: 'ABS',
  20: 'ABS FL',
  21: 'Tax Muni FL',
  22: 'Muni FL',
  26: 'CD FL',
  30: 'Other FX',
  31: 'Other FL',
  40: 'Other Amort FX',
  41: 'Other Amort FL'
};
