import { SafeKeepingPosition } from '@bakerweb/models';

export function squishSafeKeepingPositions(safeKeepingPositions: SafeKeepingPosition[]): SafeKeepingPosition[] {
  const keyGen = (safeKeepingPosition: SafeKeepingPosition) =>
    `${safeKeepingPosition.safekeepingCode}_${safeKeepingPosition.pledgeCode}`;

  const skPledgeCodeMap = safeKeepingPositions.reduce((map, skPosition) => {
    const key = keyGen(skPosition);
    const existingEntry = map.get(key);

    if (existingEntry) {
      existingEntry.pledgedAmount += skPosition.pledgedAmount;
    } else {
      map.set(key, skPosition);
    }

    return map;
  }, new Map<string, SafeKeepingPosition>());

  return Array.from(skPledgeCodeMap.values());
}
