import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from '@bakerweb/client-environment';

export const clientHttp = async (urlFragment: string, options: AxiosRequestConfig = {}) => {
  try {
    const session = await fetchAuthSession();
    const token = session?.tokens?.idToken;

    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`
    };

    const response = await axios({
      url: `${environment.baseHttpUrl}${urlFragment}`,
      method: options.method || 'POST',
      headers,
      data: options.data || null,
      params: options.params || null,
      ...options
    });

    return response.data;
  } catch (error) {
    console.error('HTTP Request Error:', error);
    throw error;
  }
};
