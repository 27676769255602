import { Pledge } from '@bakerweb/models';

export const alphabetizePledgeCodes = (pledgeCodes: Pledge[]) => {
  return [...pledgeCodes].sort((a, b) => {
    const nameA = a.pledgeeName.toLowerCase();
    const nameB = b.pledgeeName.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
