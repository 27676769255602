import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { GET_USER_PERMISSIONS } from '../graphql/queries/getUserPermissions';
import { userPortfolioState } from '@bakerweb/client-state';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const useUserPermissions = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const [portfolios, setPortfolios] = useRecoilState(userPortfolioState);
  const { data, loading, error } = useQuery(GET_USER_PERMISSIONS, {
    skip: route !== 'authenticated',
    fetchPolicy: 'cache-first',
    onCompleted: () => {
      console.info('Permissions fetched successfully.');
    },
    onError: (error) => {
      console.error('Error fetching permissions: ', error);
    }
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setPortfolios(data.user.portfolios);
    }
  }, [data, loading, error, setPortfolios]);

  return {
    data,
    loading,
    error,
    portfolios
  };
};
