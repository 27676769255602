import { replace } from 'lodash';
import numeral from 'numeral';

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fCurrencyForPledgingGrid(number: number) {
  const isNegative = number < 0;
  const formattedNumber = numeral(Math.abs(number)).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
  return isNegative ? `+${formattedNumber}` : formattedNumber;
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.00%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
