export const environment = {
  production: false,
  name: 'dev',
  baseApiUrl: 'https://bakerweb-api-dev.gobakersolutions.com',
  graphQLUrl: 'https://graphql-dev.gobakersolutions.com/graphql',
  baseHttpUrl: 'https://graphql-dev.gobakersolutions.com/api',
  awsExports: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_mFuwB4TFg',
    aws_user_pools_web_client_id: 'a9j2vmn05gjhv4s5ev7t06e3e',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'ON',
    aws_cognito_mfa_types: ['TOTP'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: []
    },
    aws_cognito_verification_mechanisms: ['EMAIL']
  }
};
