import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { MIGRATE_SK_POSITIONS } from '../graphql/mutations/migrateSKPositions';
import { useRecoilValue } from 'recoil';
import { selectedPortfolioState } from '@bakerweb/client-state';

export const useMigrateSkPositions = () => {
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const [migrateSkPositionsMutation, { data, loading, error }] = useMutation(MIGRATE_SK_POSITIONS);

  const migrateSkPositions = async ({ from, to, asOfDate }: any) => {
    const variables = {
      portfolioId,
      from,
      to,
      asOfDate
    };

    try {
      await migrateSkPositionsMutation({ variables });
      toast.success('Safekeeping codes changed successfully!');
    } catch (err) {
      console.error(err);
      toast.error('There was an error changing sk codes.');
    }
  };

  return { migrateSkPositions, data, loading, error };
};
