import { MultiUploadResponse, UploadErrorResponse } from '@bakerweb/models';
import { useCallback, useState } from 'react';
import { clientHttp } from '../client.http';

export const useS3Upload = () => {
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState<UploadErrorResponse[]>([]);
  const [successes, setSuccesses] = useState<string[]>([]);

  const uploadFiles = useCallback(async (files: File[], path: string) => {
    setUploading(true);
    setErrors([]);
    setSuccesses([]);

    const formData = new FormData();
    formData.append('path', path);
    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const urlFragment = `/pledging/object/upload`;
      const response = (await clientHttp(urlFragment, {
        method: 'POST',
        data: formData
      })) as MultiUploadResponse;

      const successfulUploads = files
        .filter((file) => !response.errors.find((err: UploadErrorResponse) => err.fileName === file.name))
        .map((file) => file.name);

      setSuccesses(successfulUploads);
      setErrors(response.errors);
    } catch (error) {
      setErrors([{ fileName: 'unknown', errorMessage: (error as Error).message }]);
    } finally {
      setUploading(false);
    }
  }, []);

  return {
    uploading,
    errors,
    successes,
    uploadFiles
  };
};
