import { ExtendFile } from '@bakerweb/models';
import numeral from 'numeral';

const FORMAT_PDF = ['pdf'];
const FORMAT_TEXT = ['txt'];
const FORMAT_WORD = ['doc', 'docx'];
const FORMAT_EXCEL = ['xls', 'xlsx', 'csv'];
const FORMAT_ZIP = ['zip', 'rar', 'iso', '7z'];
const FORMAT_BAKER = ['ipf'];
const FORMAT_IMAGE = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // future proofing

const iconUrl = (icon: string) => `/assets/icons/files/${icon}.svg`;

export function fileFormat(fileUrl: string | undefined) {
  let format;

  switch (fileUrl?.includes(fileTypeByUrl(fileUrl))) {
    case FORMAT_TEXT.includes(fileTypeByUrl(fileUrl)):
      format = 'txt';
      break;
    case FORMAT_ZIP.includes(fileTypeByUrl(fileUrl)):
      format = 'zip';
      break;
    case FORMAT_WORD.includes(fileTypeByUrl(fileUrl)):
      format = 'word';
      break;
    case FORMAT_EXCEL.includes(fileTypeByUrl(fileUrl)):
      format = 'excel';
      break;
    case FORMAT_PDF.includes(fileTypeByUrl(fileUrl)):
      format = 'pdf';
      break;
    case FORMAT_BAKER.includes(fileTypeByUrl(fileUrl)):
      format = 'txt';
      break;
    case FORMAT_IMAGE.includes(fileTypeByUrl(fileUrl)):
      format = 'txt';
      break;
    default:
      format = fileTypeByUrl(fileUrl);
  }

  return format;
}

export function fileThumb(fileUrl: string) {
  let thumb;

  switch (fileFormat(fileUrl)) {
    case 'txt':
      thumb = iconUrl('ic_txt');
      break;
    case 'zip':
      thumb = iconUrl('ic_zip');
      break;
    case 'word':
      thumb = iconUrl('ic_word');
      break;
    case 'excel':
      thumb = iconUrl('ic_excel');
      break;
    case 'pdf':
      thumb = iconUrl('ic_pdf');
      break;
    case 'image':
      thumb = iconUrl('ic_file');
      break;
    default:
      thumb = iconUrl('ic_file');
  }
  return thumb;
}

export function fileTypeByUrl(fileUrl = '') {
  return (fileUrl && fileUrl.split('.').pop()) || '';
}

export function fileNameByUrl(fileUrl: string) {
  return fileUrl.split('/').pop();
}

export function fileData(file: ExtendFile | string) {
  // Url
  if (typeof file === 'string') {
    return {
      key: file,
      preview: file,
      name: fileNameByUrl(file),
      type: fileTypeByUrl(file)
    };
  }

  // File
  return {
    key: file.preview || file.name,
    name: file.name,
    size: file.size,
    path: file.path,
    type: file.type,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate
  };
}

type InputValue = string | number | null;

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
