export const TAXOPTIONS = [
  {
    label: '0%',
    value: 0
  },
  {
    label: '21%',
    value: 21
  },
  {
    label: '29.6%',
    value: 29.6
  },
  {
    label: '33.4%',
    value: 33.4
  },
  {
    label: '37%',
    value: 37
  },
  {
    label: '40.8%',
    value: 40.8
  }
];
