import { useQuery } from '@apollo/client';
import { Pledge } from '@bakerweb/models';
import { alphabetizePledgeCodes } from '@bakerweb/utils';
import { useEffect, useState } from 'react';
import { GET_PLEDGE_CODES } from '../graphql/queries/getPledgeCodes';
import { useRecoilValue } from 'recoil';
import { selectedPortfolioState } from '@bakerweb/client-state';

export const useGetPledgeCodes = () => {
  const [pledgeCodes, setPledgeCodes] = useState<Pledge[]>([]);
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const { loading, error, data, refetch } = useQuery(GET_PLEDGE_CODES, {
    variables: { portfolioId },
    skip: !portfolio,
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data && data.pledgees) {
      const sortedPledgeesByName = alphabetizePledgeCodes(data.pledgees);
      setPledgeCodes(sortedPledgeesByName);
    }
  }, [data]);

  return { loading, error, pledgeCodes, refetch };
};
