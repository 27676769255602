import { gql } from '@apollo/client';

export const MIGRATE_SK_POSITIONS = gql`
  mutation MigrateSkPositions($portfolioId: String!, $from: String!, $to: String!, $asOfDate: Date) {
    migrateSafeKeepingPositionsByPledgeCode(portfolioId: $portfolioId, from: $from, to: $to, asOfDate: $asOfDate) {
      affected
      success
      errorMessage
    }
  }
`;
