import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { Iconify } from '../iconify/Iconify';
import { toast } from 'react-toastify';
import { useGetSkInstitutions, useMigrateSkPositions } from '@bakerweb/client-services';
import { ReportDateToggle } from './ReportDataToggle';
import { fAsOfDate } from '@bakerweb/utils';
import { SafeKeepingCode } from '@bakerweb/models';

export function SafekeepingForm() {
  const [toSkCode, setToSkCode] = useState<SafeKeepingCode | null>(null);
  const [fromSkCode, setFromSkCode] = useState<SafeKeepingCode | null>(null);
  const [canSave, setCanSave] = useState(false);
  const [selectedAsOfDateFormatted, setSelectedAsOfDateFormatted] = useState<string | null>(null);
  const { safeKeepingInstitutions } = useGetSkInstitutions();
  const { migrateSkPositions } = useMigrateSkPositions();

  const handleAsOfDateSelect = useCallback(
    (newDate: string) => {
      const formattedDate = fAsOfDate(newDate);
      setSelectedAsOfDateFormatted(formattedDate);
    },
    [fAsOfDate]
  );

  const toggleCanSave = () => {
    if (toSkCode && fromSkCode && selectedAsOfDateFormatted) {
      setCanSave(!canSave);
    }
  };

  const handleSave = async () => {
    if (fromSkCode && toSkCode && selectedAsOfDateFormatted && canSave) {
      try {
        await migrateSkPositions({
          from: fromSkCode.safeKeepingCode,
          to: toSkCode.safeKeepingCode,
          asOfDate: selectedAsOfDateFormatted
        });
        toast.success('Changes saved successfully!');
      } catch (error) {
        toast.error('Error saving changes.');
      }
    } else {
      toast.error('Please fill out all fields to proceed.');
    }
  };

  const getOptionLabel = useCallback(
    (option: SafeKeepingCode) => `${option.safeKeepingName} (${option.safeKeepingCode})`,
    []
  );

  return (
    <Box>
      <Typography id="Global SK Modal Header" variant="h6" component="h2" sx={{ mb: 2 }}>
        Change Safekeeping
      </Typography>
      <ReportDateToggle onDateSelect={handleAsOfDateSelect} />
      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <Autocomplete
          id="fromSKCode"
          options={safeKeepingInstitutions}
          getOptionLabel={getOptionLabel}
          value={fromSkCode}
          onChange={(event, newValue) => {
            setFromSkCode(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="From Safekeeping" />}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          id="toSKCode"
          options={safeKeepingInstitutions}
          getOptionLabel={getOptionLabel}
          value={toSkCode}
          onChange={(event, newValue) => {
            setToSkCode(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="To Safekeeping" />}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox checked={canSave} onChange={toggleCanSave} />}
          label="I am aware this change will affect all selected global safekeeping codes."
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={!canSave}
          startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </FormControl>
    </Box>
  );
}
