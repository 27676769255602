import { Box, IconButton, PaperProps, Stack, Typography } from '@mui/material';
import { Iconify } from '../iconify/Iconify';
import { FileThumbnail } from './FileThumbnail';
import { FileList } from '@bakerweb/models';

interface Props extends PaperProps {
  file: FileList;
  handleDownload: () => void;
}

export function FileCardDownload({ file, handleDownload, sx, ...other }: Props) {
  const { fileName, lastModified, size } = file;

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        p: 2.5,
        borderRadius: 2,
        position: 'relative',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20
        },
        ...sx
      }}
      {...other}
    >
      <FileThumbnail file="document" sx={{ width: 40, height: 40 }} />

      <Stack
        sx={{
          width: 1,
          flexGrow: { sm: 1 },
          minWidth: { sm: '1px' }
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {fileName}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {lastModified ? new Date(lastModified).toLocaleDateString() : 'Unknown Date'}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {size ? `${(size / 1024).toFixed(2)} KB` : 'Unknown Size'}
        </Typography>
      </Stack>

      <Box
        sx={{
          top: 8,
          right: 8,
          flexShrink: 0,
          position: 'unset'
        }}
      >
        <IconButton onClick={handleDownload}>
          <Iconify icon="eva:cloud-download-outline" />
        </IconButton>
      </Box>
    </Stack>
  );
}
