import { gql } from '@apollo/client';

export const GET_SK_INSTITUTIONS = gql`
  query GetSkInstitutions {
    safeKeepingInstitutions {
      recId
      safeKeepingCode
      safeKeepingName
    }
  }
`;
