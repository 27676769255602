import { gql } from '@apollo/client';

export const UPDATE_AS_OF_DATE = gql`
  mutation updateAsOfDate($asOfDateUpdateDto: AsOfDateUpdateDto!) {
    updateAsOfDate(asOfDateUpdateDto: $asOfDateUpdateDto) {
      affected
      success
      errorMessage
    }
  }
`;
