import { Card, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
import ReactApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import { BaseOptionChart } from '@bakerweb/charts';
import { fCurrency, fPercent } from '@bakerweb/utils';

const SPARK_DATA = [{ data: [1000, 1000, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010] }];
const PERCENT = 1.1;

const RootStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none',
  position: 'relative',
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

export function TrendsCard() {
  const sparkOptions = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    xaxis: { labels: { show: false } },
    yaxis: { labels: { show: false } },
    stroke: { width: 4 },
    legend: { show: false },
    grid: { show: false },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fCurrency(seriesName),
        title: {
          formatter: () => ''
        }
      }
    },
    fill: { gradient: { opacityFrom: 0.56, opacityTo: 0.56 } }
  });
  return (
    <RootStyle>
      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="h5">Market Trend</Typography>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Icon width={20} height={20} icon={PERCENT >= 0 ? trendingUpFill : trendingDownFill} />
          <Typography variant="subtitle2" component="span" sx={{ ml: 0.5 }}>
            {PERCENT > 0 && '+'}
            {fPercent(PERCENT)}
          </Typography>
          <Typography variant="body2" component="span" sx={{ opacity: 0.72 }}>
            &nbsp;past 12 months
          </Typography>
        </Stack>
      </Stack>

      <ReactApexChart type="area" series={SPARK_DATA} options={sparkOptions} height={80} />
    </RootStyle>
  );
}
