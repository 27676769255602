import { useEffect, useRef, useState } from 'react';

import { alpha } from '@mui/material/styles';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import { MenuPopover, MIconButton, SvgIconStyle } from '@bakerweb/components';
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';

export const AccountPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [email, setUserEmail] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await fetchUserAttributes();
        if (currentUser.email) {
          setUserEmail(currentUser.email);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Account">
        <MIconButton
          ref={anchorRef}
          onClick={handleOpen}
          aria-label="Account Popover"
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            color: (theme) => theme.palette.primary.main,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            })
          }}
        >
          <SvgIconStyle src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: '80%', height: '80%' }} />
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 300 }}>
        <Box sx={{ my: 2, px: 2.5, textAlign: 'left' }}>
          <Typography variant="subtitle1" noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" aria-label="Sign Out" onClick={() => signOut()}>
            Sign Out
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};
