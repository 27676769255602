import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_AS_OF_DATES } from '../graphql/queries/getAsOfDates';
import { useRecoilValue } from 'recoil';
import { selectedPortfolioState } from '@bakerweb/client-state';

interface AsOfDate {
  asOfDate: string;
}

export const useGetAsOfDates = () => {
  const [asOfDates, setAsOfDates] = useState<AsOfDate[]>([]);
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const { loading, error, data } = useQuery(GET_AS_OF_DATES, {
    variables: { portfolioId },
    skip: !portfolio,
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data && data.asOfDates) {
      setAsOfDates(data.asOfDates);
    }
  }, [data]);

  return { loading, error, asOfDates };
};
