import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { environment } from '@bakerweb/client-environment';
import { fetchAuthSession } from 'aws-amplify/auth';
import fetch from 'cross-fetch';

const httpLink = new HttpLink({
  uri: environment.graphQLUrl,
  fetch
});

const authLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    fetchAuthSession()
      .then((session) => {
        const token = session?.tokens?.idToken;
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`
          }
        }));

        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        };

        forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        observer.error(error);
      });
  });
});

const link = authLink.concat(httpLink);

export const gqlClient = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
