export function convertFAS115(fAS115: string | undefined): string {
  switch (fAS115) {
    case 'A':
      return 'AFS';
    case 'H':
      return 'HTM';
    case 'T':
      return 'TRD';
    case '0':
      return 'N/A';
    default:
      return 'N/A';
  }
}
