import { gql } from '@apollo/client';

export const GET_POSITIONS = gql`
  query GetPositions(
    $skip: Int = 0
    $take: Int = 1000
    $portfolioId: String!
    $institutionId: String
    $asOfDate: String
  ) {
    positions(skip: $skip, take: $take, portfolioId: $portfolioId, institutionId: $institutionId, asOfDate: $asOfDate) {
      positionId
      positionMasterId
      fAS115
      cusip
      secClass
      description
      coupon
      maturityDate
      callDate
      origFace
      currFace
      currFaceEnding
      bookValue
      bookPrice
      marketValue
      acctgAvgLife
      gainLoss
      rating {
        spRating
        moodyRating
      }
      safeKeepingPledgedSum
      safeKeepingPositions {
        recId
        positionMasterId
        safekeepingCode
        pledgeCode
        pledgedAmount
        pledgee {
          pledgeeName
          pledgeCode
        }
        currFace
      }
      isDeleted
      disposedRemoved
      swapPortfolioId
    }
  }
`;
