import { useRef, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { MenuPopover, MIconButton } from '@bakerweb/components';

interface EmailAddresses {
  [key: string]: string;
}

const emailAddresses: EmailAddresses = {
  BBA: 'bbadepartment@gobaker.com',
  IRRM: 'support@gobaker.com',
  Clearing: 'clearing@gobaker.com',
  Loans: 'loans@gobaker.com',
  'CD Funding': 'funding@gobaker.com',
  FSG: 'FSG@gobaker.com'
};

export const ContactPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailClick = (department: keyof EmailAddresses) => {
    const email = emailAddresses[department];
    const subject = encodeURIComponent('Client Inquiry From Web App');
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  return (
    <>
      <Tooltip title="Contact Us">
        <MIconButton
          ref={anchorRef}
          onClick={handleOpen}
          aria-label="Contact Us Popover"
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            color: (theme) => theme.palette.primary.main,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            })
          }}
        >
          <EmailTwoToneIcon sx={{ width: '80%', height: '80%' }} />
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 300 }}>
        <Box sx={{ my: 2, px: 2, textAlign: 'left' }}>
          <Typography variant="subtitle1" noWrap>
            Contact Us
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box>
          <Typography variant="subtitle1" sx={{ pl: 2, pt: 1 }}>
            Baker Departments
          </Typography>
          <List>
            {Object.keys(emailAddresses).map((department) => (
              <ListItem key={department} disablePadding>
                <ListItemButton sx={{ color: theme.palette.primary.main }} onClick={() => handleEmailClick(department)}>
                  <ListItemIcon>
                    <MailOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={department} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
          <Typography variant="caption" sx={{ color: theme.palette.error.dark }}>
            If you are experiencing a problem with this app, click the "?" button in the bottom right.
          </Typography>
        </Box>
      </MenuPopover>
    </>
  );
};
