import { useQuery } from '@apollo/client';
import { ValidPosition } from '@bakerweb/models';
import { useEffect, useState } from 'react';
import { GET_POSITIONS } from '../graphql/queries/getPositions';
import { useRecoilValue } from 'recoil';
import { selectedPortfolioState } from '@bakerweb/client-state';

export const useGetPositions = (asOfDate: string, skip = 0, take = 1000) => {
  const [positions, setPositions] = useState<ValidPosition[]>([]);
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const shouldSkipQuery = asOfDate === null || asOfDate === '' || !portfolio;
  const { loading, error, data, refetch } = useQuery(GET_POSITIONS, {
    variables: { portfolioId, asOfDate, skip, take },
    skip: shouldSkipQuery,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data && data.positions) {
      setPositions(data.positions);
    }
  }, [data]);

  return { loading, error, positions, refetch };
};
