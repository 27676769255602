import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SK_INSTITUTIONS } from '../graphql/queries/getSkInstitutions';
import { SafeKeepingCode } from '@bakerweb/models';
import { alphabetizeSafekeeping } from '@bakerweb/utils';

export const useGetSkInstitutions = () => {
  const [safeKeepingInstitutions, setSafeKeepingInstitutions] = useState<SafeKeepingCode[]>([]);
  const { loading, error, data } = useQuery(GET_SK_INSTITUTIONS);

  useEffect(() => {
    if (data && data.safeKeepingInstitutions) {
      const sortedSafeKeepingInstitutions = alphabetizeSafekeeping(data.safeKeepingInstitutions);
      setSafeKeepingInstitutions(sortedSafeKeepingInstitutions);
    }
  }, [data]);

  return { loading, error, safeKeepingInstitutions };
};
