import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import { UploadErrorResponse } from '@bakerweb/models';

export interface ConfirmUploadDialogProps extends Omit<DialogProps, 'title'> {
  title: React.ReactNode;
  content?: string | undefined;
  open: boolean;
  onClose: VoidFunction;
  action?: React.ReactNode;
  errors?: UploadErrorResponse[];
  successes?: string[];
}

export function ConfirmUploadDialog({
  title,
  content,
  action,
  open,
  onClose,
  errors = [],
  successes = [],
  ...other
}: ConfirmUploadDialogProps) {
  const theme = useTheme();

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ mb: 2, pb: 2, backgroundColor: theme.palette.primary.main, color: 'white' }}>
        {title}
      </DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}>{content}</DialogContent>}

      <DialogContent dividers>
        {successes.length > 0 && (
          <Typography variant="subtitle1">
            {successes.length} file{successes.length > 1 && 's'} uploaded successfully!
          </Typography>
        )}
        {errors.length > 0 && (
          <>
            <Typography variant="subtitle1" color="error">
              {errors.length} file{errors.length > 1 && 's'} failed. Please try these files again.
            </Typography>
            <List>
              {errors.map((error, index) => (
                <ListItem key={index} disableGutters>
                  <ListItemText primary={`${error.fileName}: ${error.errorMessage}`} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {action}
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
