import { gql } from '@apollo/client';

export const GET_PLEDGE_CODES = gql`
  query GetPledgeCodes($portfolioId: String!) {
    pledgees(portfolioId: $portfolioId) {
      recId
      pledgeCode
      pledgeeName
    }
  }
`;
