import { Box, IconButton, PaperProps, Stack, Typography } from '@mui/material';
import { Iconify } from '../iconify/Iconify';
import { FileThumbnail } from './FileThumbnail';

interface Props extends PaperProps {
  type: string;
  id: string;
  url: string;
}

export function FileCard({ type, id, url, sx, ...other }: Props) {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        p: 2.5,
        borderRadius: 2,
        position: 'relative',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20
        },
        ...sx
      }}
      {...other}
    >
      <FileThumbnail file={type} sx={{ width: 40, height: 40 }} />

      <Stack
        sx={{
          width: 1,
          flexGrow: { sm: 1 },
          minWidth: { sm: '1px' }
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {id}
        </Typography>
      </Stack>

      <Box
        sx={{
          top: 8,
          right: 8,
          flexShrink: 0,
          position: 'unset'
        }}
      >
        <IconButton href={url} target="_blank">
          <Iconify icon="eva:external-link-outline" />
        </IconButton>
      </Box>
    </Stack>
  );
}
