import { gql } from '@apollo/client';

export const UPDATE_SAFE_KEEPING_POSITIONS = gql`
  mutation updateSafeKeepingPositions($safeKeepingPositionUpdateDtos: [SafeKeepingPositionUpdateDto!]!) {
    updateSafeKeepingPositions(safeKeepingPositionUpdateDtos: $safeKeepingPositionUpdateDtos) {
      recId
      portfolioId
      asOfDate
      positionMasterId
      safekeepingCode
      safekeepingReceipt
      pledgeCode
      pledgedAmount
      createdDt
      createdBy
      modifiedDt
      modifiedBy
      isDirty
      deletionId
      isDeleted
    }
  }
`;
