/**
 DO NOT add local, transient, router, or any non-global state to this file.
 **/

import { UserPortfolio } from '@bakerweb/models';
import { atom } from 'recoil';

export const userPortfolioState = atom<UserPortfolio[]>({
  key: 'userPermissionsState',
  default: []
});

export const selectedPortfolioState = atom<UserPortfolio | undefined>({
  key: 'selectedPortfolioState',
  default: undefined
});
