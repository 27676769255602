import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material';
import { MotionViewport, TextAnimate, varFadeInRight } from '@bakerweb/components';

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // backgroundImage: 'url(/assets/background/overlay_1.svg), url(/assets/images/hero.jpg)',
  backgroundImage: 'url(/assets/background/overlay_4.jpg)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 300,
    padding: 0
  }
}));

const StyledContent = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    top: 80,
    textAlign: 'left',
    position: 'absolute'
  }
}));

export function LoansHero() {
  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <StyledContent>
          <TextAnimate text="Baker" sx={{ color: '#fff', mr: 2 }} variants={varFadeInRight} />
          <TextAnimate text="Loan" sx={{ color: '#fff', mr: 2 }} variants={varFadeInRight} />
          <TextAnimate text="Solutions" sx={{ color: '#fff' }} variants={varFadeInRight} />
          <br />

          <Stack spacing={2} display="inline-flex" direction="row" sx={{ mb: 10 }}>
            <Typography color={'info.main'} variant="h4">
              More Solutions. Same Strategic Partner.
            </Typography>
          </Stack>
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}
