export const acceptedFileTypesLoans = [
  'text/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/x-7z-compressed',
  'application/octet-stream',
  'application/xml',
  '.ipf'
];

export const acceptedFileTypeLoansForUpload = {
  'text/*': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/zip': [],
  'application/x-7z-compressed': [],
  'application/octet-stream': [],
  'application/xml': [],
  '.ipf': []
};
