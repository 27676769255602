import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fCurrency } from '@bakerweb/utils';

interface Props {
  bgColor: 'success.dark' | 'primary.dark';
  loading: boolean;
  title: string;
  bookValue: number;
  currFace: number;
  marketValue: number;
}

export function MarketValueAccordion({ bgColor, loading, title, bookValue, currFace, marketValue }: Props) {
  return (
    <Accordion sx={{ bgcolor: bgColor, color: 'white', borderRadius: '8px' }}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Typography variant="h6">{title}</Typography>
        {loading || (!loading && marketValue === 0) ? (
          <CircularProgress size={20} sx={{ color: 'white', alignSelf: 'center', mr: 1 }} />
        ) : (
          <Typography variant="h6">{fCurrency(marketValue)}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">Current Face</Typography>
          <Typography variant="subtitle2">{fCurrency(currFace)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">Book Value</Typography>
          <Typography variant="subtitle2">{fCurrency(bookValue)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">Market Value</Typography>
          <Typography variant="subtitle2">{fCurrency(marketValue)}</Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
