import { SafeKeepingCode } from '@bakerweb/models';

export const alphabetizeSafekeeping = (skCodes: SafeKeepingCode[]) => {
  return [...skCodes].sort((a, b) => {
    const nameA = a.safeKeepingCode.toLowerCase();
    const nameB = b.safeKeepingCode.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
