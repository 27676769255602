import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/loans';

const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  loans: {
    root: ROOTS_DASHBOARD,
    auto: path(ROOTS_DASHBOARD, '/auto'),
    cre: path(ROOTS_DASHBOARD, '/cre'),
    residential: path(ROOTS_DASHBOARD, '/residential')
  }
};

export const publicSidebarConfig = [
  {
    subheader: 'Loans',
    items: [
      {
        title: 'Loans',
        path: PATH_DASHBOARD.root,
        icon: <AccountBalanceWalletIcon />,
        children: [
          { title: 'Auto', path: PATH_DASHBOARD.loans.auto },
          { title: 'CRE', path: PATH_DASHBOARD.loans.cre },
          { title: 'Residential', path: PATH_DASHBOARD.loans.residential }
        ]
      }
    ]
  }
];
