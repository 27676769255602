import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import DescriptionIcon from '@mui/icons-material/Description';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/bank';

const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    admin: path(ROOTS_DASHBOARD, '/admin'),
    risk: path(ROOTS_DASHBOARD, '/admin/risk'),
    upload: path(ROOTS_DASHBOARD, '/admin/upload'),
    search: path(ROOTS_DASHBOARD, '/search'),
    positions: path(ROOTS_DASHBOARD, '/positions'),
    apm: path(ROOTS_DASHBOARD, '/apm'),
    cds: path(ROOTS_DASHBOARD, '/cds'),
    files: path(ROOTS_DASHBOARD, '/files'),
    cusip: path(ROOTS_DASHBOARD, '/cusip/:positionId'),
    users: path(ROOTS_DASHBOARD, '/users')
  },
  pledging: {
    root: path(ROOTS_DASHBOARD, '/pledging'),
    portfolio: path(ROOTS_DASHBOARD, '/pledging/portfolio'),
    pledgeCodes: path(ROOTS_DASHBOARD, '/pledging/codes'),
    pledgeSafekeeping: path(ROOTS_DASHBOARD, '/pledging/safekeeping'),
    reports: path(ROOTS_DASHBOARD, '/pledging/reports')
  }
};

export const clientSidebarConfig = [
  {
    subheader: 'Credit Criterion Check',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.dashboard,
        icon: <DashboardIcon />
      },

      {
        title: 'Pledging',
        path: PATH_DASHBOARD.pledging.root,
        icon: <AssuredWorkloadIcon />,
        children: [
          { title: 'My Portfolio', path: PATH_DASHBOARD.pledging.portfolio },
          { title: 'Pledge Codes', path: PATH_DASHBOARD.pledging.pledgeCodes },
          { title: 'Safekeeping', path: PATH_DASHBOARD.pledging.pledgeSafekeeping },
          { title: 'Pledging Reports', path: PATH_DASHBOARD.pledging.reports }
        ]
      },
      {
        title: 'APM',
        path: PATH_DASHBOARD.general.apm,
        icon: <MonitorHeartIcon />
      },
      {
        title: 'Files',
        path: PATH_DASHBOARD.general.files,
        icon: <DescriptionIcon />
      },
      {
        title: 'CD Offerings',
        path: PATH_DASHBOARD.general.cds,
        icon: <CardMembershipIcon />
      },
      {
        title: 'Prospectus Search',
        path: PATH_DASHBOARD.general.search,
        icon: <ManageSearchIcon />
      }
    ]
  }
];
