import { gql } from '@apollo/client';

export const CREATE_PLEDGEE_MUTATION = gql`
  mutation createPledgee($pledgeeCreateDto: PledgeesCreateDto!) {
    createPledgee(pledgeeCreateDto: $pledgeeCreateDto) {
      recId
      portfolioId
      pledgeCode
      pledgeeName
      createdDt
      createdBy
      modifiedDt
      modifiedBy
      isDeleted
    }
  }
`;
