/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLScalarType } from 'graphql';
import { Kind } from 'graphql/language';

export const DateType = new GraphQLScalarType({
  name: 'Date',
  description: 'Custom scalar type for Date',
  parseValue(value: any) {
    // Parse the incoming value as a date
    return new Date(value);
  },
  serialize(value: any) {
    // Serialize the Date object to string for output
    return value.toISOString();
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING) {
      // Parse the string literal as a date
      return new Date(ast.value);
    }
    return null; // Invalid input, return null
  }
});
