import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

export function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 150, height: 150, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 718 156.26">
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            className="cls-1"
            d="M129.94,289s24.5-23.34,8.38-41a29.71,29.71,0,0,0-10-7.31c-9.27-4.86-35.4-6.93-63.44,14.77C52.81,266,40.08,280.26,41.05,302.54c.63,14.29,7.76,22.73,18.56,19.3-5.75-18.15-2.32-36.8,17.28-52.15,18.78-14.7,38.11-14.76,43.57-8.18,5.77,7,3.92,20.85-9.68,28.06-9,4.77-9.6,5.31-21,8.94-1.13.37-2,1.5.34,1.05a44.44,44.44,0,0,1,12.21-.16c9,1.06,32.77,4.64,35.61,29.4,3,25.9-24.5,48-60.52,53.16-4.71.67-4.88,1.44,0,1.3,18.83-.56,51.5-4.75,70.56-24.55,25.31-26.29,12.85-64.51-18.11-69.69Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M225.57,368.29V329.38c0-17.53-9.45-27.72-28.84-27.72a56.28,56.28,0,0,0-25,5.47l4.1,12.06c6-2.49,11.43-4.11,18.77-4.11,10.44,0,16.16,5,16.16,14.05v1.24a55.42,55.42,0,0,0-18.15-2.73c-15.29,0-26.6,7-26.6,21.5v.25c0,13.18,10.94,20.27,23.37,20.27,9.94,0,16.78-4.11,21.26-9.45v8.08ZM211,344.55c0,8.2-7.46,13.79-17.41,13.79-7.08,0-12.68-3.48-12.68-9.69v-.25c0-6.34,5.6-10.19,15.05-10.19a42.81,42.81,0,0,1,15,2.61v3.73Z"
            transform="translate(-41 -232)"
          />
          <polygon
            className="cls-1"
            points="196.26 136.29 211.3 136.29 211.3 117.52 220.5 107.95 240.26 136.29 257.67 136.29 230.69 97.38 256.8 70.65 238.52 70.65 211.3 99.74 211.3 45.54 196.26 45.54 196.26 136.29 196.26 136.29"
          />
          <path
            className="cls-1"
            d="M360.21,336.59c0-18.9-10.57-35.3-31.45-35.3-18.77,0-32.07,15.41-32.07,34.18v.25c0,20.26,14.67,34.06,33.69,34.06,12.05,0,20.63-4.85,26.85-12.31l-8.83-7.83c-5.22,5.1-10.44,7.83-17.78,7.83-9.69,0-17.27-6-18.89-16.65H360c.13-1.5.25-2.86.25-4.23Zm-14.92-5.72H311.61c1.36-10.19,7.83-17.28,17-17.28,9.94,0,15.66,7.59,16.65,17.28Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M369.16,368.29h15v-25c0-17.4,9.2-26,22.38-26h.87V301.41c-11.56-.5-19.15,6.21-23.25,16v-14.8h-15v65.64Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M457.76,369.78a52.92,52.92,0,0,0,35.08-13.36V321.6H456.76v8.86h26.59v21.72a41.14,41.14,0,0,1-25.22,8.74c-21.22,0-34.58-15.48-34.58-36.33v-.25c0-19.47,13.86-35.83,33.21-35.83,12.36,0,19.85,4.12,26.59,10l6.37-7.49c-8.74-7.37-17.85-11.61-32.59-11.61-26.21,0-43.82,21.22-43.82,45.19v.25c0,25,17,44.94,44.45,44.94Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M507.19,368.29h9.62V342.57c0-19.48,11.48-29.59,25.59-29.59h.75V302.62c-12.24-.5-21.6,7.24-26.34,18V303.74h-9.62v64.55Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M612.06,336.08v-.25a33.65,33.65,0,0,0-67.29.25v.25a33.07,33.07,0,0,0,33.46,33.45,33.51,33.51,0,0,0,33.83-33.7Zm-9.86.25c0,13.6-9.74,24.84-23.72,24.84-13.61,0-23.84-11.24-23.84-25.09v-.25c0-13.61,9.61-25,23.59-25,13.61,0,24,11.36,24,25.22v.25Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M647.86,369.66c11.11,0,17.73-5.62,22.1-12.61v11.24h9.49V303.74H670v37c0,12.1-8.37,20.22-19.35,20.22-11.36,0-17.85-7.49-17.85-19.48v-37.7h-9.62v40.08c0,15.35,9.12,25.84,24.72,25.84Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M759,336.08v-.25c0-21.23-15.35-33.46-30.84-33.46-11.85,0-19.47,6.62-24.71,14.36v-13h-9.62v84.52h9.62V355.93c5,7.24,12.73,13.73,24.71,13.73,15.61,0,30.84-12.49,30.84-33.58Zm-9.86.12c0,15.61-10.24,24.84-22.72,24.84-12,0-23.35-10-23.35-25v-.25c0-14.73,11.36-24.85,23.35-24.85,12.23,0,22.72,9.74,22.72,25v.25Z"
            transform="translate(-41 -232)"
          />
          <path
            className="cls-1"
            d="M165.17,270.13h4.18V237.38h12.28v-3.81H152.9v3.81h12.27v32.75Zm20.12,0h4V254.67c0-5.07,3.45-8.46,8.1-8.46s7.46,3.13,7.46,8.14v15.78h4V253.36c0-6.42-3.82-10.81-10.35-10.81a10.23,10.23,0,0,0-9.24,5.28V232h-4v38.13Zm32-11.86h21.42c.05-.63.05-1,.05-1.41,0-7.94-4.6-14.31-12.59-14.31-7.47,0-12.95,6.27-12.95,14.1,0,8.41,6.06,14.1,13.47,14.1a14,14,0,0,0,11.13-5.06l-2.51-2.25a11.16,11.16,0,0,1-8.51,3.82c-4.75,0-8.93-3.3-9.51-9Zm0-3.24c.58-5.22,4.13-9.08,8.78-9.08,5.32,0,8.14,4.17,8.61,9.08Z"
            transform="translate(-41 -232)"
          />
        </g>
      </svg>
    </Box>
  );
}
